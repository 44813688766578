/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

//tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";



.upload-image{
    width:75px;
    cursor: pointer;
}
.uploaded-image{
    width:200px;
    cursor: pointer;
}
.custom-loading{
    --background: #e3edff;
    --spinner-color: --var(--ion-color-primary);

    color: --var(--ion-color-primary);
}

.attachment{
    cursor: pointer;
}

.tooltip {
    @apply invisible absolute;
}
  
.has-tooltip:hover .tooltip {
    @apply visible z-20;
}

@media only screen and (min-width: 768px) {
    .viewModal{
        --min-width:90%;
        --min-height:90%;
        --height: 90%;
        --width: 90%
    }

    .receptionImageModal{
        --min-width:75%;
        --min-height:75%;
        --height: 75%;
        --width: 75%
    }
}

